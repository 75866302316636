@import "variables";

button.no-style {
  background: none;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  border-radius: 0;
  min-width: initial;
  -webkit-user-select: none; /* for button */
  -moz-user-select: none;
  -ms-user-select: none;
  outline: none;
  cursor: pointer;
  text-align: initial;
}

.mat-flat-button.mat-gray {
  background-color: $site-bg-color-300;
  color: $site-text-color-200;
  border: 1px solid $site-border-color-200;

  &:hover {
    background-color: lighten($site-bg-color-300, 2%);
  }
}