@import "_variables";

//always should horizontal scrollbar
.ps {
  position: relative;

  > .ps__rail-x {
    opacity: 0.6;
  }
}

@import "~perfect-scrollbar/css/perfect-scrollbar";