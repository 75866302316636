@import "_variables";

.be-modal {
  width: $modal-content-width;
  
  .dialog-loading-bar {
    position: absolute;
    top: 0;
    left: 0;
  }

  .mat-dialog-container {
    position: relative;
  }

  .modal-header {
    display: flex;
    align-items: center;
    position: relative;
    color: $modal-header-text-color;
    margin: $modal-header-margin;

    > h2 {
      font-size: $modal-header-font-size;
      font-weight: $modal-header-font-weight;
      flex: 1 1 auto;
      margin: 0;
      line-height: 30px;
    }
  }

  .mat-dialog-content {
    position: relative;
    padding-top: 1px;
    padding-bottom: 1px;
  }

  .mat-dialog-actions {
    justify-content: flex-end;
    padding-bottom: 16px;
  }

  .close-button {
    flex: 0 0 $modal-close-button-size;
    width: $modal-close-button-size;
    height: $modal-close-button-size;
    line-height: $modal-close-button-size;
    color: $modal-close-button-color;
    transition: transform ease 0.2s;
    z-index: 999;

    &:hover {
      transform: rotate(90deg);
    }

    .mat-icon {
      width: $modal-close-button-size;
      height: $modal-close-button-size;
    }
  }

  .errors {
    color: $modal-error-text-color;

    > .error {
      padding: 10px 0;

      &:last-of-type {
        margin-bottom: 20px;
      }
    }
  }

  .input-container {
    > input, > textarea, select {
      background-color: $modal-input-bg-color;
    }

    > .error {
      color: $modal-error-text-color;
    }

    > p {
      margin-top: 8px;
      line-height: 1.5;
      font-size: 1.4rem;
      color: $modal-input-hint-text-color;
    }
  }

  .no-input-error {
    padding: 10px 0;
    color: $danger-bg-color;
  }
}

.mat-dialog-container {
  background-color: $site-bg-color-100;
  color: $site-text-color-400;
}