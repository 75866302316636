@import "../scss/variables";

.auth-page {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: $login-page-bg-color;
  color: $login-page-text-color;

  > .auth-panel {
    position: relative;
    max-width: 90%;
    width: 400px;
    padding: 30px 35px;
    background-color: $login-page-panel-bg-color;
    border: 1px solid $login-page-border-color;

    .general-error {
      padding: 8px 0;
      color: $danger-bg-color;
      text-align: center;
    }

    .color-secondary {
      color: $login-page-secondary-text-color;
    }

    .social-icons {
      flex: 0 0 50%;
      height: 36px;
      padding: 3px 0 0;
    }

    .logo {
      display: block;
      width: 100%;
      margin: 0 auto 30px;

      > img {
        display: block;
        margin: auto;
        width: 200px;
      }
    }

    .big-social-auth-button {
      width: 100%;
      text-align: center;

      button {
        line-height: 48px;
        width: 100%;
      }

      svg {
        display: block;
      }
    }

    .input-container {
      position: relative;

      > input {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: .5px;
        height: 47px;
        padding: 6px 12px;
        border: 1px solid $login-page-border-color;
        background-color: $login-page-input-bg-color;
        color: inherit;
      }

      > .error {
        position: absolute;
        top: 0;
        left: 100%;
        margin: 0;
        padding: 0 20px;
        white-space: nowrap;
        background-color: $danger-bg-color;
        color: $danger-text-color;
        line-height: 45px;
        max-width: 350px;
        overflow: hidden;
        text-overflow: ellipsis;

        @media only screen and (max-width: $medium) {
          position: static;
          padding: 0;
          background-color: inherit;
          color: $danger-bg-color;
          line-height: initial;
          max-width: 100%;
          margin: 8px 0 0;
        }
      }

      > .forgot-password {
        cursor: pointer;
        position: absolute;
        right: 13px;
        top: 14px;

        &:hover {
          color: $link-color;
        }
      }
    }
  }
}